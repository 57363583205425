<template lang="">
    <div class="sidebar w-full bg-black/50 xl:w-[245px] fixed top-0 bottom-0 -left-full xl:relative xl:min-h-screen xl:left-0 xl:flex-shrink-0 z-30 xl:z-20">
        <button @click="closeSideMenu($event)" class="absolute right-5 top-5 text-gray-900 text-lg font-medium px-3 py-1 rounded-full bg-white xl:hidden"><font-awesome-icon icon="times" /></button>
        <div class="pb-5 w-3/4 md:w-[245px] flex flex-col bg-gray-900 shadow-md shadow-black/30 z-30 h-full overflow-y-scroll xl:overflow-y-hidden xl:z-20 sidebar-child">
            <div class="flex flex-col items-center justify-center mb-8 px-6 py-5 bg-[#21222E]">
                <img :src="user.userProfile" :alt="user.userName" class="h-20 md:h-24 w-20 md:w-24 rounded-full mb-3">
                <span class="font-medium text-white">{{ user.userName }}</span>
            </div>
            <span class="block mb-4 font-medium mx-6 px-1 text-gray-50 border-b-2 border-gray-300">Menu</span>
            <div class="admin-menu pl-6">
                <ul class="mb-5">
                    <li class="mb-2" v-show="user.userType === 'Manager'">
                        <router-link class="parent-menu-link" to="/dashboard" exact>
                            <font-awesome-icon icon="home" class="mr-4" /><span class="flex-grow">Dashboard</span>
                        </router-link>
                    </li>
                    <li class="mb-2" v-show="user.userType === 'Manager'">
                        <router-link class="parent-menu-link" to="/branches" exact>
                            <font-awesome-icon icon="building" class="mr-4" /><span class="flex-grow">Branches</span>
                        </router-link>
                    </li>
                    <li class="mb-2">
                        <router-link class="parent-menu-link" to="/transaction" exact>
                            <font-awesome-icon icon="cart-arrow-down" class="mr-4" /><span class="flex-grow">POS</span>
                        </router-link>
                    </li>
                    <li class="mb-2">
                        <router-link to="/stock" class="parent-menu-link" @click.capture="toggleMenu('submenu1', $event)">
                            <font-awesome-icon icon="cubes" class="mr-4" /><span class="flex-grow">Products Stock</span>
                            <font-awesome-icon icon="angle-right" class="mr-4 sub-arrow transition-all ease-in-out duration-300" />
                        </router-link>
                        <ul class="sub-menu submenu1 px-4 text-sm py-3 bg-gray-800" :style="openSub1 ? 'display: block' : 'display: none'">
                            <li class="mb-2">
                                <router-link to="/stock/price-and-quantity" class="child-menu-link" exact>
                                    <span class="flex-grow"><font-awesome-icon icon="tags" class="mr-2" />Price & Quantity</span>
                                </router-link>
                            </li>
                            <li class="mb-2">
                                <router-link to="/stock/old-stock" class="child-menu-link" exact>
                                    <span class="flex-grow"><font-awesome-icon icon="tags" class="mr-2" />Old Stock Records</span>
                                </router-link>
                            </li>
                            <li class="mb-2">
                                <router-link to="/stock/product-categories" class="child-menu-link" exact>
                                    <span class="flex-grow"><font-awesome-icon icon="file-edit" class="mr-2" />Product Categories</span>
                                </router-link>
                            </li>
                            <li class="mb-2">
                                <router-link to="/stock/new-product" class="child-menu-link" exact>
                                    <span class="flex-grow"><font-awesome-icon icon="cube" class="mr-2" />New Product</span>
                                </router-link>
                            </li>
                            <li class="mb-2">
                                <router-link to="/stock/edit-product" class="child-menu-link" exact>
                                    <span class="flex-grow"><font-awesome-icon icon="file-edit" class="mr-2" />Edit Product</span>
                                </router-link>
                            </li>
                            <li class="mb-2">
                                <router-link to="/stock/transfer-product" class="child-menu-link" exact>
                                    <span class="flex-grow"><font-awesome-icon icon="dolly" class="mr-2" />Transfer Products</span>
                                </router-link>
                            </li>
                            <li class="mb-2">
                                <router-link to="/stock/transfer-history" class="child-menu-link" exact>
                                    <span class="flex-grow"><font-awesome-icon icon="file-edit" class="mr-2" />Transfer History</span>
                                </router-link>
                            </li>
                            <li class="mb-2">
                                <router-link to="/stock/create-invoice" class="child-menu-link" exact>
                                    <span class="flex-grow"><font-awesome-icon icon="file-lines" class="mr-2" />Create Invoice</span>
                                </router-link>
                            </li>
                            <li class="mb-2">
                                <router-link to="/stock/saved-invoices" class="child-menu-link" exact>
                                    <span class="flex-grow"><font-awesome-icon icon="file-lines" class="mr-2" />Saved Invoices</span>
                                </router-link>
                            </li>
                            <li class="mb-2">
                                <router-link to="/stock/damaged-products" class="child-menu-link" exact>
                                    <span class="flex-grow"><font-awesome-icon icon="trash" class="mr-2" />Damaged Products</span>
                                </router-link>
                            </li>
                        </ul>
                    </li>
                    <li class="mb-2" v-show="user.userType === 'Manager'">
                        <router-link to="/purchases" class="parent-menu-link" @click.capture="toggleMenu('submenu7', $event)">
                            <font-awesome-icon icon="cubes" class="mr-4" /><span class="flex-grow">Purchases</span>
                            <font-awesome-icon icon="angle-right" class="mr-4 sub-arrow transition-all ease-in-out duration-300" />
                        </router-link>
                        <ul class="sub-menu submenu7 px-4 text-sm py-3 bg-gray-800" :style="openSub7 ? 'display: block' : 'display: none'">
                            <li class="mb-2">
                                <router-link to="/purchases/new-purchase" class="child-menu-link" exact>
                                    <span class="flex-grow"><font-awesome-icon icon="dolly" class="mr-2" />New Purchase</span>
                                </router-link>
                            </li>
                            <li class="mb-2">
                                <router-link to="/purchases/purchase-history" class="child-menu-link" exact>
                                    <span class="flex-grow"><font-awesome-icon icon="file-edit" class="mr-2" />History</span>
                                </router-link>
                            </li>
                            <li class="mb-2">
                                <router-link to="/purchases/purchase-payments" class="child-menu-link" exact>
                                    <span class="flex-grow"><font-awesome-icon icon="sack-dollar" class="mr-2" />Payments</span>
                                </router-link>
                            </li>
                        </ul>
                    </li>
                    <li class="mb-2">
                        <router-link to="/sales" class="parent-menu-link" @click.capture="toggleMenu('submenu2', $event)">
                            <font-awesome-icon icon="hand-holding-dollar" class="mr-4" /><span class="flex-grow">Sales</span>
                            <font-awesome-icon icon="angle-right" class="mr-4 sub-arrow transition-all ease-in-out duration-300" />
                        </router-link>
                        <ul class="sub-menu submenu2 px-4 text-sm py-3 bg-gray-800" :style="openSub2 ? 'display: block' : 'display: none'">
                            <li class="mb-2">
                                <router-link to="/sales/cash-sales" class="child-menu-link" exact>
                                    <span class="flex-grow"><font-awesome-icon icon="sack-dollar" class="mr-2" />Cash Sales</span>
                                </router-link>
                            </li>
                            <li class="mb-2">
                                <router-link to="/sales/credit-sales" class="child-menu-link" exact>
                                    <span class="flex-grow"><font-awesome-icon icon="comment-dollar" class="mr-2" />Credit Sales</span>
                                </router-link>
                            </li>
                            <li v-show="user.userType === 'Manager'" class="mb-2">
                                <router-link to="/sales/staff-sales" class="child-menu-link" exact>
                                    <span class="flex-grow"><font-awesome-icon icon="sack-dollar" class="mr-2" />Staff Sales</span>
                                </router-link>
                            </li>
                            <li class="mb-2">
                                <router-link to="/sales/returned-items" class="child-menu-link" exact>
                                    <span class="flex-grow"><font-awesome-icon icon="cubes" class="mr-2" />Items Returned</span>
                                </router-link>
                            </li>
                        </ul>
                    </li>
                    <li class="mb-2">
                        <router-link to="/deposits" class="parent-menu-link" @click.capture="toggleMenu('submenu8', $event)">
                            <font-awesome-icon icon="hand-holding-dollar" class="mr-4" /><span class="flex-grow">Customer Deposits</span>
                            <font-awesome-icon icon="angle-right" class="mr-4 sub-arrow transition-all ease-in-out duration-300" />
                        </router-link>
                        <ul class="sub-menu submenu8 px-4 text-sm py-3 bg-gray-800" :style="openSub8 ? 'display: block' : 'display: none'">
                            <li class="mb-2">
                                <router-link to="/deposits/new-entry" class="child-menu-link" exact>
                                    <span class="flex-grow"><font-awesome-icon icon="cart-arrow-down" class="mr-2" />New Entry</span>
                                </router-link>
                            </li>
                            <li class="mb-2">
                                <router-link to="/deposits/deposit-history" class="child-menu-link" exact>
                                    <span class="flex-grow"><font-awesome-icon icon="file-edit" class="mr-2" />History</span>
                                </router-link>
                            </li>
                            <li class="mb-2">
                                <router-link to="/deposits/deposit-payment" class="child-menu-link" exact>
                                    <span class="flex-grow"><font-awesome-icon icon="sack-dollar" class="mr-2" />Payments History</span>
                                </router-link>
                            </li>
                            <li class="mb-2">
                                <router-link to="/deposits/deposit-completed" class="child-menu-link" exact>
                                    <span class="flex-grow"><font-awesome-icon icon="sack-dollar" class="mr-2" />Completed Transactions</span>
                                </router-link>
                            </li>
                        </ul>
                    </li>
                    <li class="mb-2">
                        <router-link to="/hiring-services" class="parent-menu-link" @click.capture="toggleMenu('submenu9', $event)">
                            <font-awesome-icon icon="dolly" class="mr-4" /><span class="flex-grow">Items Hiring</span>
                            <font-awesome-icon icon="angle-right" class="mr-4 sub-arrow transition-all ease-in-out duration-300" />
                        </router-link>
                        <ul class="sub-menu submenu9 px-4 text-sm py-3 bg-gray-800" :style="openSub9 ? 'display: block' : 'display: none'">
                            <li class="mb-2">
                                <router-link to="/hiring-services/items" class="child-menu-link" exact>
                                    <span class="flex-grow"><font-awesome-icon icon="cubes" class="mr-2" />Item List</span>
                                </router-link>
                            </li>
                            <li class="mb-2">
                                <router-link to="/hiring-services/new-entry" class="child-menu-link" exact>
                                    <span class="flex-grow"><font-awesome-icon icon="cart-arrow-down" class="mr-2" />Hire Items</span>
                                </router-link>
                            </li>
                            <li class="mb-2">
                                <router-link to="/hiring-services/history" class="child-menu-link" exact>
                                    <span class="flex-grow"><font-awesome-icon icon="file-edit" class="mr-2" />Hiring History</span>
                                </router-link>
                            </li>
                        </ul>
                    </li>
                    <li class="mb-2">
                        <router-link to="/services" class="parent-menu-link" @click.capture="toggleMenu('submenu10', $event)">
                            <font-awesome-icon icon="file-lines" class="mr-4" /><span class="flex-grow">Services</span>
                            <font-awesome-icon icon="angle-right" class="mr-4 sub-arrow transition-all ease-in-out duration-300" />
                        </router-link>
                        <ul class="sub-menu submenu10 px-4 text-sm py-3 bg-gray-800" :style="openSub10 ? 'display: block' : 'display: none'">
                            <li class="mb-2">
                                <router-link to="/services/services-list" class="child-menu-link" exact>
                                    <span class="flex-grow"><font-awesome-icon icon="cubes" class="mr-2" />Services List</span>
                                </router-link>
                            </li>
                            <li class="mb-2">
                                <router-link to="/services/render-service" class="child-menu-link" exact>
                                    <span class="flex-grow"><font-awesome-icon icon="cart-arrow-down" class="mr-2" />Render Service</span>
                                </router-link>
                            </li>
                            <li class="mb-2">
                                <router-link to="/services/history" class="child-menu-link" exact>
                                    <span class="flex-grow"><font-awesome-icon icon="file-edit" class="mr-2" />Servicing History</span>
                                </router-link>
                            </li>
                        </ul>
                    </li>
                    <li class="mb-2 hidden">
                        <router-link to="/trek-sales" class="parent-menu-link" @click.capture="toggleMenu('submenu6', $event)">
                            <font-awesome-icon icon="cart-arrow-down" class="mr-4" /><span class="flex-grow">Trek Records</span>
                            <font-awesome-icon icon="angle-right" class="mr-4 sub-arrow transition-all ease-in-out duration-300" />
                        </router-link>
                        <ul class="sub-menu submenu6 px-4 text-sm py-3 bg-gray-800" :style="openSub6 ? 'display: block' : 'display: none'">
                            <li class="mb-2">
                                <router-link to="/trek-sales/record-sales" class="child-menu-link" exact>
                                    <span class="flex-grow"><font-awesome-icon icon="sack-dollar" class="mr-2" />Record Sales</span>
                                </router-link>
                            </li>
                            <li class="mb-2">
                                <router-link to="/trek-sales/view-sales" class="child-menu-link" exact>
                                    <span class="flex-grow"><font-awesome-icon icon="sack-dollar" class="mr-2" />Sales History</span>
                                </router-link>
                            </li>
                            <li class="mb-2">
                                <router-link to="/trek-sales/trek-debtors" class="child-menu-link" exact>
                                    <span class="flex-grow"><font-awesome-icon icon="sack-dollar" class="mr-2" />Debtors</span>
                                </router-link>
                            </li>
                        </ul>
                    </li>
                    <li class="mb-2">
                        <router-link to="/finance" class="parent-menu-link" @click.capture="toggleMenu('submenu3', $event)">
                            <font-awesome-icon icon="sack-dollar" class="mr-4" /><span class="flex-grow">Finances</span>
                            <font-awesome-icon icon="angle-right" class="mr-4 sub-arrow transition-all ease-in-out duration-300" />
                        </router-link>
                        <ul class="sub-menu submenu3 px-4 text-sm py-3 bg-gray-800" :style="openSub3 ? 'display: block' : 'display: none'">
                            <li class="mb-2" v-show="user.userType === 'Manager'">
                                <router-link to="/finance/debtors-transactions" class="child-menu-link" exact>
                                    <span class="flex-grow"><font-awesome-icon icon="sack-dollar" class="mr-2" />Debtors Transactions</span>
                                </router-link>
                            </li>
                            <li class="mb-2" v-show="user.userType === 'Manager'">
                                <router-link to="/finance/debtor-transaction-history" class="child-menu-link" exact>
                                    <span class="flex-grow"><font-awesome-icon icon="file-edit" class="mr-2" />Debtors Statement</span>
                                </router-link>
                            </li>
                            <li class="mb-2" v-show="user.userType === 'Manager'">
                                <router-link to="/finance/creditors-transactions" class="child-menu-link" exact>
                                    <span class="flex-grow"><font-awesome-icon icon="comment-dollar" class="mr-2" />Creditors Transactions</span>
                                </router-link>
                            </li>
                            <li class="mb-2">
                                <router-link to="/finance/customer-payments" class="child-menu-link" exact>
                                    <span class="flex-grow"><font-awesome-icon icon="sack-dollar" class="mr-2" />Debtors Payments</span>
                                </router-link>
                            </li>
                            <li class="mb-2" v-show="user.userType === 'Manager'">
                                <router-link to="/finance/supplier-payments" class="child-menu-link" exact>
                                    <span class="flex-grow"><font-awesome-icon icon="comment-dollar" class="mr-2" />Creditors Payments</span>
                                </router-link>
                            </li>
                            <li class="mb-2" v-show="user.userType === 'Manager'">
                                <router-link to="/finance/staff-salary" class="child-menu-link" exact>
                                    <span class="flex-grow"><font-awesome-icon icon="sack-dollar" class="mr-2" />Staff Salary</span>
                                </router-link>
                            </li>
                            <li class="mb-2">
                                <router-link to="/finance/new-expenditure" class="child-menu-link" exact>
                                    <span class="flex-grow"><font-awesome-icon icon="shopping-basket" class="mr-2" />New Expenditure</span>
                                </router-link>
                            </li>
                            <li class="mb-2">
                                <router-link to="/finance/expense-records" class="child-menu-link" exact>
                                    <span class="flex-grow"><font-awesome-icon icon="shopping-basket" class="mr-2" />Expenses Records</span>
                                </router-link>
                            </li>
                            <li class="mb-2" v-show="user.userType === 'Manager'">
                                <router-link to="/finance/finance-summary" class="child-menu-link" exact>
                                    <span class="flex-grow"><font-awesome-icon icon="cubes" class="mr-2" />Finances Summary</span>
                                </router-link>
                            </li>
                        </ul>
                    </li>
                    <li class="mb-2" v-show="user.userType === 'Manager'">
                        <router-link to="/staff" class="parent-menu-link" @click.capture="toggleMenu('submenu4', $event)">
                            <font-awesome-icon icon="users" class="mr-4" /><span class="flex-grow">Staff</span>
                            <font-awesome-icon icon="angle-right" class="mr-4 sub-arrow transition-all ease-in-out duration-300" />
                        </router-link>
                        <ul class="sub-menu submenu4 px-4 text-sm py-3 bg-gray-800" :style="openSub4 ? 'display: block' : 'display: none'">
                            <li class="mb-2">
                                <router-link to="/staff/staff-records" class="child-menu-link" exact>
                                    <span class="flex-grow"><font-awesome-icon icon="users" class="mr-2" />Staff Records</span>
                                </router-link>
                            </li>
                            <li class="mb-2">
                                <router-link to="/staff/new-staff" class="child-menu-link" exact>
                                    <span class="flex-grow"><font-awesome-icon icon="user-plus" class="mr-2" />New Staff</span>
                                </router-link>
                            </li>
                            <li class="mb-2">
                                <router-link to="/staff/edit-permissions" class="child-menu-link" exact>
                                    <span class="flex-grow"><font-awesome-icon icon="user-edit" class="mr-2" />Edit Staff Permissions</span>
                                </router-link>
                            </li>
                            <li class="mb-2">
                                <router-link to="/staff/edit-login" class="child-menu-link" exact>
                                    <span class="flex-grow"><font-awesome-icon icon="user-edit" class="mr-2" />Edit Staff Login</span>
                                </router-link>
                            </li>
                            <li class="mb-2">
                                <router-link to="/staff/transfer-staff" class="child-menu-link" exact>
                                    <span class="flex-grow"><font-awesome-icon icon="home" class="mr-2" />Transfer Staff</span>
                                </router-link>
                            </li>
                        </ul>
                    </li>
                    <li class="mb-2">
                        <router-link to="/partners" class="parent-menu-link" @click.capture="toggleMenu('submenu5', $event)">
                            <font-awesome-icon icon="users" class="mr-4" /><span class="flex-grow">Business Partners</span>
                            <font-awesome-icon icon="angle-right" class="mr-4 sub-arrow transition-all ease-in-out duration-300" />
                        </router-link>
                        <ul class="sub-menu submenu5 px-4 text-sm py-3 bg-gray-800" :style="openSub5 ? 'display: block' : 'display: none'">
                            <li class="mb-2">
                                <router-link to="/partners/customers-records" class="child-menu-link" exact>
                                    <span class="flex-grow"><font-awesome-icon icon="users" class="mr-2" />Customers</span>
                                </router-link>
                            </li>
                            <li class="mb-2">
                                <router-link to="/partners/suppliers-records" class="child-menu-link" exact>
                                    <span class="flex-grow"><font-awesome-icon icon="truck" class="mr-2" />Suppliers</span>
                                </router-link>
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
            <!-- End of Menu -->

            <span class="block mb-4 font-medium mx-6 px-1 text-gray-50 border-b-2 border-gray-300">General</span>
            <div class="admin-menu flex-grow pl-6 mb-10">
                <ul class="mb-10">
                    <li class="mb-2">
                        <router-link class="parent-menu-link" to="/profile" exact>
                            <font-awesome-icon icon="user" class="mr-4" /><span class="flex-grow">Profile</span>
                        </router-link>
                    </li>
                    <li class="mb-2">
                        <router-link class="parent-menu-link" to="/settings" exact>
                            <font-awesome-icon icon="cog" class="mr-4" /><span class="flex-grow">Settings</span>
                        </router-link>
                    </li>
                    <li class="mb-2">
                        <button class="parent-menu-link w-full text-left" @click="signOut">
                            <font-awesome-icon icon="sign-out" class="mr-4" /><span class="flex-grow">Logout</span>
                        </button>
                    </li>
                </ul>
            </div>
            <div class="text-[12px] text-gray-500 text-center">Developed By: <span class="font-bold">Mikidad Abdulai</span></div>
        </div>
    </div>

    <div class="fixed left-0 xl:left-[245px] right-0 top-0 bg-gray-100 shadow-md sahdow-black/30 z-20">
        <div class="py-3 px-4 md:px-5 flex justify-between items-center">
            <div class="flex items-center">
                <button @click="showSideMenu($event)" class="mr-2.5 outline-none border-none focus:outline-none focus:border-none xl:hidden sidebar-open">
                    <span class="block w-5 h-0.5 bg-stone-900 mb-[5px]"></span>
                    <span class="block w-5 h-0.5 bg-stone-900 mb-[5px]"></span>
                    <span class="block w-5 h-0.5 bg-stone-900"></span>
                </button>
                <span class="text-gray-900 text-lg md:text-2xl">{{currentTitle}}</span>
            </div>
            <div class="flex items-center">
                <ul class="ml-4">
                    <li class="relative group">
                        <img :src="user.userProfile" :alt="user.userName" class="w-9 h-9 rounded-full cursor-pointer">
                        <div class="absolute top-full right-0 w-40 bg-transparent pt-2 z-10 hidden group-hover:block">
                            <ul class="bg-white rounded shadow shadow-black/30 p-2">
                                <li>
                                    <router-link to="/profile" class="block text-sm p-2 py-2.5 border-b border-gray-300 transition-all">
                                        <font-awesome-icon icon="user" class="mr-2" /><span>Profile</span>
                                    </router-link>
                                </li>
                                <li>
                                    <router-link to="/settings" class="block text-sm p-2 py-2.5 border-b border-gray-300 transition-all">
                                        <font-awesome-icon icon="cog" class="mr-2" /><span>Settings</span>
                                    </router-link>
                                </li>
                                <li>
                                    <button @click="signOut" class="block text-sm p-2 py-2.5 transition-all">
                                        <font-awesome-icon icon="sign-out" class="mr-2" /><span>Logout</span>
                                    </button>
                                </li>
                            </ul>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>
<script>
import { defineComponent } from 'vue';
import { mapGetters, mapMutations } from 'vuex';
import { logoutUser } from '@/services/StaffServices';
const $ = require("jquery")

export default defineComponent({
    name: 'SideBar',
    data() {
        return {
            isSideMenuDisplayed: false,
        }
    },
    props: {
        openSub1: {
            type: Boolean,
            default: false
        },
        openSub2: {
            type: Boolean,
            default: false
        },
        openSub3: {
            type: Boolean,
            default: false
        },
        openSub4: {
            type: Boolean,
            default: false
        },
        openSub5: {
            type: Boolean,
            default: false
        },
        openSub6: {
            type: Boolean,
            default: false
        },
        openSub7: {
            type: Boolean,
            default: false
        },
        openSub8: {
            type: Boolean,
            default: false
        },
        openSub9: {
            type: Boolean,
            default: false
        },
        openSub10: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        ...mapMutations(['destroyUser']),
        toggleMenu(menu, event) {
            event.preventDefault();
            // let subMenu = $(`.${menu}`);
            // if(subMenu.is(":visible")) {
            //     subMenu.slideUp();
            // } else {
            //     subMenu.slideDown();
            // }

            let $subMenu = $(`.${menu}`);
            if($subMenu.is(":visible")) {
                $subMenu.slideUp();
            } else {
                let $allSubMenus = $(".admin-menu").find(".sub-menu").not($subMenu);
                $allSubMenus.slideUp(400, function(){
                    $subMenu.slideDown(400);
                });
            }
        },
        showSideMenu(event){
            event.preventDefault();
            let $mainSideBar = $(".sidebar");
            if(!this.isSideMenuDisplayed) {
                $mainSideBar.animate({
                    left: "0px"
                }, 400);
                this.isSideMenuDisplayed = true;
            } else {
                $mainSideBar.animate({
                    left: "-100%"
                }, 400);
                this.isSideMenuDisplayed = false;
            }
        },
        closeSideMenu(){
            let $mainSideBar = $(".sidebar");
            $mainSideBar.animate({
                left: "-100%"
            }, 400);
            this.isSideMenuDisplayed = false;
        },
        async signOut() {
            await logoutUser();
            this.destroyUser();
            this.$router.push('/');
        }
    },
    computed: {
        ...mapGetters({
            currentTitle: "getTitle",
            user: "getUser"
        })
    }
})
</script>