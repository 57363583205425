import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import ProductsContainer from '../views/stock/Products.vue'
import PurchaseContainer from '../views/purchase/PurchaseContainer.vue'
import SalesContainer from '../views/sales/SalesContainer.vue'
import MobileContainer from '../views/mobile/MobileContainer.vue'
import FinanceContainer from '../views/finances/FinanceContainer.vue'
import StaffContainer from '../views/staff/StaffContainer.vue'
import PartnerContainer from "../views/partners/PartnerContainer.vue"
import DepositContainer from "../views/deposits/DepositContainer.vue"
import HiringContainer from "../views/services/HiringContainer.vue"
import ServicesContainer from "../views/services/ServicesContainer.vue"
import { getLoginStatus, checkPermission } from '@/services/StaffServices'

async function verifyUserPermission(to, from) {

  let isPermitted = false;
  await checkPermission(to.meta.permission).then(response => {
    isPermitted = response.permission;
  });

  if(!isPermitted){
    return false;
  }

}

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: {
      isAuth: false
    }
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('../views/Dashboard.vue'),
    meta: {
      isAuth: true
    }
  },
  {
    path: '/branches',
    name: 'branches',
    component: () => import('../views/Branches.vue'),
    meta: {
      isAuth: true
    }
  },
  {
    path: '/profile',
    name: 'profile',
    component: () => import('../views/Profile.vue'),
    meta: {
      isAuth: true
    }
  },
  {
    path: '/settings',
    name: 'settings',
    component: () => import('../views/Settings.vue'),
    meta: {
      isAuth: true,
      permission: 'update_business'
    },
    beforeEnter: verifyUserPermission
  },
  {
    path: '/stock',
    name: 'Products',
    component: ProductsContainer,
    meta: {
      isAuth: true
    },
    children: [
      {
        path: '',
        redirect: '/stock/price-and-quantity'
      },
      {
        path: 'price-and-quantity',
        component: () => import('../views/stock/PriceQuantity.vue')
      },
      {
        path: 'old-stock',
        component: () => import('../views/stock/PreviousStock.vue')
      },
      {
        path: 'product-categories',
        component: () => import('../views/stock/ProductCategories.vue')
      },
      {
        path: 'new-product',
        component: () => import('../views/stock/CreateProduct.vue'),
        //component: () => import('../views/stock/NewProductTemplate.vue'),
        meta: {
          permission: 'add_product'
        },
        beforeEnter: verifyUserPermission
      },
      {
        path: 'edit-product',
      component: () => import('../views/stock/EditProduct.vue'),
        meta: {
          permission: 'update_product'
        },
        beforeEnter: verifyUserPermission
      },
      {
        path: 'transfer-product',
        component: () => import('../views/stock/TransferProduct.vue'),
        meta: {
          permission: 'move_product'
        },
        beforeEnter: verifyUserPermission
      },
      {
        path: 'transfer-history',
        component: () => import('../views/stock/TransferHistory.vue')
      },
      {
        path: 'create-invoice',
        component: () => import('../views/stock/CreateInvoice.vue')
        //component: () => import('../views/stock/InvoiceTemplate.vue'),
      },
      {
        path: 'saved-invoices',
        component: () => import('../views/stock/InvoiceHistory.vue')
      },
      {
        path: 'damaged-products',
        component: () => import('../views/stock/DamagedStock.vue')
      }
    ]
  },
  {
    path: '/purchases',
    name: 'Purchases',
    component: PurchaseContainer,
    meta: {
      isAuth: true
    },
    children: [
      {
        path: '',
        redirect: '/purchases/purchase-history'
      },
      {
        path: 'new-purchase',
        component: () => import('../views/purchase/NewPurchase.vue')
      },
      {
        path: 'purchase-history',
        component: () => import('../views/purchase/PurchaseHistory.vue')
      },
      {
        path: 'purchase-payments',
        component: () => import('../views/purchase/PurchasePayments.vue')
      }
    ]
  },
  {
    path: '/transaction',
    name: 'sell-item',
    component: () => import('../views/Transaction.vue'),
    //component: () => import('../views/NewSalesTemplate.vue'),
    meta: {
      isAuth: true
    }
  },
  {
    path: '/sales',
    name: 'ProductSales',
    component: SalesContainer,
    meta: {
      isAuth: true
    },
    children: [
      {
        path: '',
        redirect: '/sales/cash-sales'
      },
      {
        path: 'cash-sales',
        component: () => import('../views/sales/GeneralCashSales.vue')
        //component: () => import('../views/sales/CashSales.vue')
      },
      {
        path: 'credit-sales',
        component: () => import('../views/sales/GeneralCreditSales.vue'),
        //component: () => import('../views/sales/CreditSales.vue')
      },
      {
        path: 'staff-sales',
        component: () => import('../views/sales/GeneralStaffSales.vue')
        // component: () => import('../views/sales/StaffSales.vue')
      },
      {
        path: 'returned-items',
        component: () => import('../views/sales/ReturnedSales.vue')
      }
    ]
  },
  {
    path: '/hiring-services',
    name: 'Hiring',
    component: HiringContainer,
    meta: {
      isAuth: true
    },
    children: [
      {
        path: '',
        redirect: '/hiring-services/items'
      },
      {
        path: 'items',
        component: () => import('../views/services/ItemsList.vue')
      },
      {
        path: 'new-entry',
        component: () => import('../views/services/HireItems.vue')
      },
      {
        path: 'history',
        component: () => import('../views/services/HiringHistory.vue')
      }
    ]
  },
  {
    path: '/services',
    name: 'Servicing',
    component: ServicesContainer,
    meta: {
      isAuth: true
    },
    children: [
      {
        path: '',
        redirect: '/services/services-list'
      },
      {
        path: 'services-list',
        component: () => import('../views/services/ServicesList.vue')
      },
      {
        path: 'render-service',
        component: () => import('../views/services/RenderService.vue')
      },
      {
        path: 'history',
        component: () => import('../views/services/ServiceHistory.vue')
      }
    ]
  },
  {
    path: '/deposits',
    name: 'DepositSales',
    component: DepositContainer,
    meta: {
      isAuth: true
    },
    children: [
      {
        path: '',
        redirect: '/deposits/new-entry'
      },
      {
        path: 'new-entry',
        component: () => import('../views/deposits/NewEntry.vue')
      },
      {
        path: 'deposit-history',
        component: () => import('../views/deposits/DepositHistory.vue')
      },
      {
        path: 'deposit-payment',
        component: () => import('../views/deposits/DepositPayment.vue')
      },
      {
        path: 'deposit-completed',
        component: () => import('../views/deposits/DepositsCompleted.vue')
      }
    ]
  },
  {
    path: '/trek-sales',
    name: 'MobileSales',
    component: MobileContainer,
    meta: {
      isAuth: true
    },
    children: [
      {
        path: '',
        redirect: '/trek-sales/new-sales'
      },
      {
        path: 'new-sales',
        component: () => import('../views/mobile/NewSales.vue')
      },
      {
        path: 'locations',
        component: () => import('../views/mobile/TreckLocations.vue')
      },
      {
        path: 'record-sales',
        component: () => import('../views/mobile/RecordTrekSales.vue')
      },
      {
        path: 'view-sales',
        component: () => import('../views/mobile/ViewSales.vue')
      },
      {
        path: 'make-payment',
        component: () => import('../views/mobile/RecordTrekPayments.vue')
      },
      {
        path: 'trek-debtors',
        component: () => import('../views/mobile/ViewTrekDebtors.vue')
      },
      {
        path: 'customers',
        component: () => import('../views/mobile/CustomerList.vue')
      }
    ]
  },
  {
    path: '/finance',
    name: 'BusinessFinances',
    component: FinanceContainer,
    meta: {
      isAuth: true
    },
    children: [
      {
        path: '',
        redirect: '/finance/customer-payments'
      },
      {
        path: 'customer-payments',
        component: () => import('../views/finances/DebtorPayments.vue')
        // component: () => import('../views/finances/CustomerPayments.vue')
      },
      {
        path: 'supplier-payments',
        component: () => import('../views/purchase/PurchasePayments.vue'),
        meta: {
          permission: 'pay_supplier'
        },
        beforeEnter: verifyUserPermission
      },
      {
        path: 'creditors-transactions',
        component: () => import('../views/finances/CreditorsTransactions.vue')
      },
      {
        path: 'debtors-transactions',
        component: () => import('../views/finances/DebtorsTransactions.vue')
      },
      {
        path: 'debtor-transaction-history',
        component: () => import('../views/finances/CustomerTransactionHistory.vue')
      },
      {
        path: 'staff-salary',
        component: () => import('../views/finances/SalaryPayments.vue'),
        meta: {
          permission: 'pay_staff'
        },
        beforeEnter: verifyUserPermission
      },
      {
        path: 'expense-records',
        component: () => import('../views/finances/ExpenseRecords.vue')
      },
      {
        path: 'new-expenditure',
        component: () => import('../views/finances/NewExpenditure.vue')
      },
      {
        path: 'finance-summary',
        component: () => import('../views/finances/FinanceSummary.vue')
      }
    ]
  },
  {
    path: '/staff',
    name: 'WorkingStaff',
    component: StaffContainer,
    meta: {
      isAuth: true
    },
    children: [
      {
        path: '',
        redirect: '/staff/staff-records'
      },
      {
        path: 'staff-records',
        component: () => import('../views/staff/AllStaff.vue')
      },
      {
        path: 'new-staff',
        component: () => import('../views/staff/AddStaff.vue'),
        meta: {
          permission: 'add_staff'
        },
        beforeEnter: verifyUserPermission
      },
      {
        path: 'edit-permissions',
        component: () => import('../views/staff/EditPermission.vue'),
        meta: {
          permission: 'update_staff'
        },
        beforeEnter: verifyUserPermission
      },
      {
        path: 'edit-login',
        component: () => import('../views/staff/EditLogin.vue'),
        meta: {
          permission: 'update_staff'
        },
        beforeEnter: verifyUserPermission
      },
      {
        path: 'transfer-staff',
        component: () => import('../views/staff/TransferStaff.vue'),
        meta: {
          permission: 'move_staff'
        },
        beforeEnter: verifyUserPermission
      }
    ]
  },
  {
    path: '/partners',
    name: 'BusinessPartners',
    component: PartnerContainer,
    meta: {
      isAuth: true
    },
    children: [
      {
        path: '',
        redirect: '/partners/customers-records'
      },
      {
        path: 'customers-records',
        component: () => import('../views/partners/CustomersList.vue')
      },
      // {
      //   path: 'customers-records',
      //   component: () => import('../views/mobile/CustomerList.vue')
      // },
      {
        path: 'suppliers-records',
        component: () => import('../views/partners/SuppliersList.vue')
      }
    ]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach(async (to, from, next) => {
  if(to.meta.isAuth) {
    let authObject = null;
    await getLoginStatus().then(function(response){
      authObject = response;
    });

    if(authObject.current) {
      next();
    } else {
      next({name: "home"});
    }
  } else {
    next();
  }
});

export default router
